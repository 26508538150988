// IdGenerator.jsx
import React, { useState } from "react"
import Seo from "../components/seo"
import { FiAlertTriangle } from "react-icons/fi"
import BudmenIdGenerator from "../components/IDGenerator"
import BlobGrid from "../components/blobGrid"

const IdGenerator = () => {
  const [idColor, setIdColor] = useState("")
  const [parameters, setParameters] = useState({})

  return (
    <div className="id-generator-container flex flex-col items-center py-8 px-4">
      <Seo title="Project ID Generator" />

      {/* Alert Message */}
      <div className="w-full mb-6">
        <div
          className="bg-indigo-100 rounded-lg flex items-center gap-2 justify-center py-3 px-4 text-xs text-indigo-700"
          role="alert"
        >
          <FiAlertTriangle className="mr-2" />
          This is a beta feature for internal use.
        </div>
      </div>

      <h1 className="title text-3xl font-bold mb-8 text-center">
        Project ID Generator
      </h1>

      <div className="flex flex-col md:flex-row items-start w-full max-w-6xl">
        {/* Generator Section */}
        <div className="generator-section w-full md:w-1/2 lg:w-1/3 md:pr-8 mb-8 md:mb-0">
          <BudmenIdGenerator
            setIdColor={setIdColor}
            setParameters={setParameters}
          />
        </div>

        {/* Blob Grid Section */}
        <div className="blob-grid-section w-full md:w-1/2 lg:w-2/3 flex justify-center items-center">
          <BlobGrid
            idColor={idColor}
            playPerformIntensity={parameters.playPerformIntensity}
            inspireInstructIntensity={parameters.inspireInstructIntensity}
            material={parameters.material}
            quantity={parameters.quantity}
            updateTrigger={parameters.updateTrigger}
          />
        </div>
      </div>
    </div>
  )
}

export default IdGenerator
