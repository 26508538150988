// BlobGrid.jsx
import React, { useEffect, useState } from "react"

// Cell Component
const Cell = ({ size, color, borderRadius, update }) => {
  const normedSize = size * 0.32

  return (
    <svg width="32" height="32" className="blob-grid-cell">
      <rect
        x={16 - normedSize / 2}
        y={16 - normedSize / 2}
        width={normedSize}
        height={normedSize}
        fill={color}
        rx={(borderRadius * normedSize) / 100}
      />
    </svg>
  )
}

// Connector Component
const Connector = ({
  size,
  color,
  backgroundColor,
  borderRadius,
  connectionProbability,
  slantInfluence,
  update,
}) => {
  const show = React.useMemo(
    () => Math.random() < connectionProbability / 100,
    [connectionProbability, update]
  )
  const rotation = React.useMemo(
    () => (Math.random() > slantInfluence ? 0 : 90),
    [slantInfluence, update]
  )

  if (!show) return <svg width="32" height="32" />

  const cellSize = size * 0.32
  const gapSize = 32 - cellSize
  const rectSize = gapSize * 2 + 32

  return (
    <svg width="32" height="32" style={{ transform: `rotate(${rotation}deg)` }}>
      <rect width="100%" height="100%" fill={color} />
      <rect
        width={rectSize}
        height={rectSize}
        rx={(borderRadius * cellSize) / 100 + gapSize / 2}
        stroke={backgroundColor}
        strokeWidth={gapSize}
        fill="none"
        x="16"
        y="16"
      />
      <rect
        width={rectSize}
        height={rectSize}
        rx={(borderRadius * cellSize) / 100 + gapSize / 2}
        stroke={backgroundColor}
        strokeWidth={gapSize}
        fill="none"
        x={16 - rectSize}
        y={16 - rectSize}
      />
    </svg>
  )
}

// BlobGrid Component
const BlobGrid = ({
  idColor,
  playPerformIntensity = 5,
  inspireInstructIntensity = 5,
  material = "P",
  quantity = "0",
  updateTrigger,
}) => {
  // Map parameters to properties
  // Adjusted grid size mapping
  const quantityValue = parseInt(quantity)
  const gridSize = Math.min(Math.floor(quantityValue / 2) + 3, 6)
  const columns = 3
  const rows = 3

  const size = 85
  const borderRadiusMap = {
    P: 10,
    W: 20,
    M: 5,
    G: 15,
    C: 0,
    E: 25,
    F: 30,
    D: 35,
    N: 40,
    U: 50,
  }
  const borderRadius = borderRadiusMap[material] || 20
  const color = idColor || "#6CD97E"
  const backgroundColor = "transparent"
  const connectionProbability = playPerformIntensity * 10 // 0-100%
  const slantInfluence = (100 + inspireInstructIntensity * 10) / 200

  const [update, setUpdate] = useState(false)

  useEffect(() => {
    // Trigger update when updateTrigger changes
    setUpdate(prev => !prev)
  }, [updateTrigger])

  // Generate cells
  const cellRows = []
  for (let i = 0; i < rows * columns; i++) {
    cellRows.push(
      <Cell
        key={`cell-${i}`}
        update={update}
        size={size}
        color={color}
        borderRadius={borderRadius}
      />
    )
  }

  // Generate connectors
  const connectors = []
  const numConnectors = (rows - 1) * (columns - 1)
  for (let x = 0; x < numConnectors; x++) {
    connectors.push(
      <Connector
        key={`connector-${x}`}
        update={update}
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        connectionProbability={connectionProbability}
        slantInfluence={slantInfluence}
      />
    )
  }

  return (
    <div className="blob-grid-container">
      <div
        className="blob-grid-main-content"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          className="blob-grid-connectors-wrapper"
          style={{
            position: "absolute",
            display: "grid",
            gridTemplateColumns: `repeat(${columns - 1}, max-content)`,
            gridGap: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {connectors}
        </div>
        <main
          className="blob-grid-main"
          style={{
            backgroundColor: "transparent",
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, max-content)`,
            gridGap: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cellRows}
        </main>
      </div>
    </div>
  )
}

export default BlobGrid
