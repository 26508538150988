// BudmenIdGenerator.jsx
import React, { useState } from "react"

// Material and Quantity mappings
const materials = {
  P: "Plastic",
  W: "Wood",
  M: "Metal",
  G: "Glass",
  C: "Concrete",
  E: "Electronics",
  F: "Fabric",
  D: "Digital",
  N: "Mixed",
  U: "Unusual",
}

const quantities = {
  0: "Prototype",
  1: "Limited Edition",
  2: "Small Run",
  3: "Medium Run",
  4: "Large Run",
  5: "Mass Production",
  6: "Large Scale Production",
  7: "Industrial Scale",
  8: "Mass Market",
  9: "Global Scale",
}

// Function to generate a color based on the ID
const generateColor = input => {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  const normalizedHash = Math.abs(hash) % 16777215 // Max value for 6-digit hex color
  const color = "#" + normalizedHash.toString(16).padStart(6, "0")

  return color
}

const BudmenIdGenerator = ({ setIdColor, setParameters }) => {
  const [playPerform, setPlayPerform] = useState("P")
  const [playPerformIntensity, setPlayPerformIntensity] = useState(5)
  const [inspireInstruct, setInspireInstruct] = useState("I")
  const [inspireInstructIntensity, setInspireInstructIntensity] = useState(5)
  const [material, setMaterial] = useState("P")
  const [quantity, setQuantity] = useState("0")
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")

  const [id, setId] = useState("")

  const generateId = () => {
    // Ensure required fields are filled
    if (!year || !month) {
      alert("Please enter both year and month.")
      return
    }

    const newId = `B-${playPerform}${playPerformIntensity}${inspireInstruct}${inspireInstructIntensity}${material}${quantity}-${year}${month}`
    const colorInput = newId.replace(/-/g, "")
    const color = generateColor(colorInput)
    setId(newId)
    setIdColor(color) // Update the color in the parent component

    // Pass the parameters to the parent
    setParameters({
      playPerformIntensity: parseInt(playPerformIntensity),
      inspireInstructIntensity: parseInt(inspireInstructIntensity),
      material,
      quantity,
      updateTrigger: Date.now(), // Force update
    })
  }

  return (
    <div className="w-full mx-auto p-6 bg-white shadow-md rounded-md">
      <div className="space-y-6">
        {/* Project Type Section */}
        <div>
          <h3 className="text-sm font-semibold mb-4">Project Type</h3>
          <div className="mb-4">
            <label
              htmlFor="playPerform"
              className="block text-gray-700 mb-2 text-xs"
            >
              Play or Perform
            </label>
            <select
              id="playPerform"
              value={playPerform}
              onChange={e => setPlayPerform(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            >
              <option value="P">Play</option>
              <option value="F">Perform</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="playPerformIntensity"
              className="block text-gray-700 mb-2 text-xs"
            >
              Play/Perform Intensity: {playPerformIntensity}
            </label>
            <input
              type="range"
              id="playPerformIntensity"
              value={playPerformIntensity}
              min="0"
              max="9"
              onChange={e => setPlayPerformIntensity(e.target.value)}
              className="w-full"
            />
          </div>
        </div>

        {/* Purpose Section */}
        <div>
          <h3 className="text-sm font-semibold mb-4">Purpose</h3>
          <div className="mb-4">
            <label
              htmlFor="inspireInstruct"
              className="block text-gray-700 mb-2 text-xs"
            >
              Inspire or Instruct
            </label>
            <select
              id="inspireInstruct"
              value={inspireInstruct}
              onChange={e => setInspireInstruct(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            >
              <option value="I">Inspire</option>
              <option value="T">Instruct</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="inspireInstructIntensity"
              className="block text-gray-700 mb-2 text-xs"
            >
              Inspire/Instruct Intensity: {inspireInstructIntensity}
            </label>
            <input
              type="range"
              id="inspireInstructIntensity"
              value={inspireInstructIntensity}
              min="0"
              max="9"
              onChange={e => setInspireInstructIntensity(e.target.value)}
              className="w-full"
            />
          </div>
        </div>

        {/* Details Section */}
        <div>
          <h3 className="text-sm font-semibold mb-4">Details</h3>
          <div className="mb-4">
            <label
              htmlFor="material"
              className="block text-gray-700 mb-2 text-xs"
            >
              Material
            </label>
            <select
              id="material"
              value={material}
              onChange={e => setMaterial(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            >
              {Object.keys(materials).map(key => (
                <option value={key} key={key}>
                  {materials[key]}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="quantity"
              className="block text-gray-700 mb-2 text-xs"
            >
              Production Type
            </label>
            <select
              id="quantity"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            >
              {Object.keys(quantities).map(key => (
                <option value={key} key={key}>
                  {quantities[key]}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Date Section */}
        <div>
          <h3 className="text-sm font-semibold mb-4">Date</h3>
          <div className="mb-4">
            <label htmlFor="year" className="block text-gray-700 mb-2 text-xs">
              Year (YY)
            </label>
            <input
              type="text"
              id="year"
              value={year}
              onChange={e => setYear(e.target.value)}
              placeholder="e.g., 23"
              maxLength="2"
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            />
          </div>

          <div>
            <label htmlFor="month" className="block text-gray-700 mb-2 text-xs">
              Month (MM)
            </label>
            <input
              type="text"
              id="month"
              value={month}
              onChange={e => setMonth(e.target.value)}
              placeholder="e.g., 07"
              maxLength="2"
              className="w-full border border-gray-300 rounded-md p-2 text-xs"
            />
          </div>
        </div>

        {/* Generate Button */}
        <div className="text-center">
          <button
            className="w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700"
            onClick={generateId}
          >
            Generate ID
          </button>
        </div>

        {/* Generated ID Display */}
        {id && (
          <div className="mt-6 bg-gray-100 p-4 rounded-md">
            <h3 className="text-lg font-semibold mb-2">Generated ID:</h3>
            <p className="text-sm font-mono">{id}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default BudmenIdGenerator
